import React from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import SiparislerList from '@solid-ui-blocks/Siparisler'
import KtLog from '../../../../../packages/solid-ui/solid-ui-blocks/src/KtLog/KtLog'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'


const MusteriSiparislerim = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes) 

  return (
    <KtLayout prp={props} cnt={content}  lightmi={false} >
      <Seo title='Müşteri Siparişleri' />
      <Divider space='5' />
      <KtLog> 
      <SiparislerList />
      </KtLog>
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageMusteriSiparislerimBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default MusteriSiparislerim
